
  import { Component, Vue } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { ModuleInterface } from '@/interface/BaseInterface'

  const tCourseDetail = namespace('tCourseDetail')

  @Component({ components: {} })
  export default class ViewFile extends Vue {
    @tCourseDetail.State('filePreview') filePreview!: ModuleInterface
    @tCourseDetail.Action('resetFilePreview') resetFilePreview!: () => void

    handleOpen() {
      console.log('open')
    }

    handleClose() {
      this.resetFilePreview()
    }
  }
