
  import { Component, Vue } from 'vue-property-decorator'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import { CourseInterface } from '@/interface/BaseInterface'
  import { assign } from 'lodash'
  import CourseApi from '@/modules/course/api/CourseApi'
  import getApi from '@/api'

  const courseApi: CourseApi = getApi('course')

  @Component({ components: { BaseEditor } })
  export default class CourseNote extends Vue {
    data: CourseInterface = {
      _id: '',
      fullname: ''
    }

    handleOpen() {
      console.log('open')
    }

    handleClose() {
      this.reset()
    }

    reset() {
      this.data = {
        _id: '',
        fullname: ''
      }
    }

    handleChange(text: string) {
      this.data.summary = text
    }

    setData(data: CourseInterface) {
      this.data = assign(this.data, data)
      if (this.$refs.editor) {
        // @ts-ignore
        this.$refs.editor.setData(data.summary)
      }
    }

    async handleSubmit() {
      try {
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            const data = await courseApi.update(this.data._id, this.data)
            this.$emit('edit', data)
            this.$message.success(this.$t('editSuccess') as string)
            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
