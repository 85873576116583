
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { SectionInterface, ModuleInterface } from '@/interface/BaseInterface'
  import QuizType from './type/QuizType.vue'
  import FileType from './type/FileType.vue'
  import getApi from '@/api'
  import { filter } from 'lodash'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'
  import SectionApi from '../../api/sectionApi'

  const sectionApi: SectionApi = getApi('section')
  const tCourseDetail = namespace('tCourseDetail')
  const tBase = namespace('base')

  @Component({
    components: { QuizType, FileType }
  })
  export default class SectionComponent extends Vue {
    @Prop({ required: true, type: Object }) data!: SectionInterface
    @Prop({ required: false, type: Boolean, default: false }) showStatus!: boolean

    @tCourseDetail.Action('setFilePreview') setFilePreview!: (data: ModuleInterface) => void
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    activeName = 'section'

    isConflict = false

    handleAddModule() {
      this.activeName = 'section'
      this.$emit('addModule', this.data)
    }

    handleEdit() {
      this.activeName = 'section'
      this.$emit('edit', this.data)
    }

    handleRemove() {
      this.activeName = 'section'
      this.$emit('remove', this.data)
    }

    async handleRemoveQuiz(data: ModuleInterface) {
      try {
        // if (data.moduleType === 'Quiz') {
        if (data.moduleType === 'File') this.isConflict = true
        await sectionApi.removeModule(this.courseId, this.data._id, data.moduleId || '')
        // await sectionApi.delete(data._id)
        this.data.modules = filter(this.data.modules, value => {
          return value._id !== data.moduleId
        })
        // }
      } catch (error) {
        console.log(error)
      }
    }

    handleFileClick(data: ModuleInterface) {
      if (this.isConflict) {
        this.isConflict = false
        return
      }
      this.setFilePreview(data)
      this.setOpenPopup({
        isOpen: true,
        module: 'viewFile'
      })
    }

    handleChangeStatusSection() {
      this.$emit('status', this.data)
    }

    handleResetStatus() {
      this.$emit('resetStatus', this.data)
    }
  }
