
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { ModuleInterface } from '@/interface/BaseInterface'
  import { assign } from 'lodash'

  @Component({ components: {} })
  export default class FileType extends Vue {
    @Prop({ required: true, type: String }) moduleId?: string
    @Prop({ required: true, type: Object }) data!: ModuleInterface
    @Prop({ required: false, type: Boolean, default: true }) showAction!: boolean

    confictClick = false

    handleCommand(command: 'remove' | 'edit') {
      if (command === 'remove') {
        this.$confirm(this.$t('deleteQuestion') as string, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$emit(
            'remove',
            assign(this.data, {
              moduleType: 'File',
              moduleId: this.moduleId
            })
          )
        })
      }
    }

    handleClick() {
      this.$emit('click', this.data)
    }
  }
