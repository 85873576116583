
  import { Component, Vue } from 'vue-property-decorator'
  import { CourseFormat, SectionInterface } from '@/interface/BaseInterface'
  import { courseFormat } from '@/config'
  import { assign } from 'lodash'
  import SectionApi from '../../api/sectionApi'
  import getApi from '@/api'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'

  const sectionApi: SectionApi = getApi('section')

  @Component({ components: { BaseEditor } })
  export default class CreateSection extends Vue {
    data: SectionInterface = {
      _id: '',
      courseId: this.courseId,
      name: ''
    }

    format: CourseFormat[] = courseFormat

    isAdd = true

    reset() {
      this.data = {
        _id: '',
        courseId: this.courseId,
        name: ''
      }
    }

    handleOpen() {
      console.log('open')
    }

    handleClose() {
      this.reset()
    }

    setData(data: SectionInterface) {
      this.reset()
      this.data = assign(this.data, data)
      this.isAdd = false
      if (this.$refs.editor) {
        // @ts-ignore
        this.$refs.editor.setData(data.description || '')
      }
    }

    handleChange(text: string) {
      this.data.description = text
    }

    async handleSubmit() {
      try {
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            const result = assign(this.data)
            if (this.data._id === '') {
              const data = await sectionApi.create(this.courseId, result)
              this.$emit('submit', data)
              this.$message.success(this.$t('createSuccess') as string)
            } else {
              const data = await sectionApi.update(this.courseId, this.data._id, this.data)
              this.$emit('edit', data)
              this.$message.success(this.$t('editSuccess') as string)
            }

            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
