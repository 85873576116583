
  import { Vue, Component, Watch } from 'vue-property-decorator'
  import getApi from '@/api'
  import { CourseInterface, SectionInterface } from '@/interface/BaseInterface'
  import SectionComponent from '../components/section/SectionComponent.vue'
  import { filter, includes, deburr, kebabCase, trim, forEach, findIndex, assign, sortBy } from 'lodash'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'
  import CreateModule from '../components/modules/CreateModule.vue'
  import CreateSection from '../components/section/CreateSection.vue'
  import ViewFile from '../components/files/ViewFile.vue'
  import SectionApi from '../api/sectionApi'
  import CourseNote from '../components/course/CourseNote.vue'

  const courseApi = getApi('course')
  const tBase = namespace('base')
  const tCourseDetail = namespace('tCourseDetail')
  const sectionApi: SectionApi = getApi('section')

  @Component({
    components: { SectionComponent, CreateModule, CreateSection, ViewFile, CourseNote }
  })
  export default class CourseDetail extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void
    @tCourseDetail.Action('setCourseActive') setCourseActive!: (data: CourseInterface) => void
    @tCourseDetail.Action('setCourseActive') resetCourseActive!: () => void

    course: CourseInterface = {
      _id: '',
      fullname: ''
    }

    search = ''

    sessionSelect = ''

    sectionChoice = ''
    isLoading = false

    get getSessions(): SectionInterface[] {
      if (trim(this.search) === '') {
        return this.course.sections || []
      }
      return filter(this.course.sections, value => {
        return includes(kebabCase(deburr(value.name)), kebabCase(deburr(this.search)))
      })
    }

    get getSessions2(): SectionInterface[] {
      const val: Record<string, any> = {
        processing: 1,
        completed: 3,
        pending: 2
      }
      const result = sortBy(this.course.sections, value => {
        const current = value.status ? val[value.status] || 0 : 0
        return current
      })
      if (trim(this.search) === '') {
        return result || []
      }
      return filter(result, value => {
        return includes(kebabCase(deburr(value.name)), kebabCase(deburr(this.search)))
      })
    }

    get getStep() {
      let step = 0
      forEach(this.getSessions, (value, key) => {
        if (value.status === 'completed') {
          step = key + 1
        }
      })
      return step
    }

    get getSectionId() {
      return this.$route.params.sectionId
    }

    @Watch('getSectionId', { immediate: true }) watchA(new_: string) {
      if (new_ && new_ !== '') {
        const a = document.getElementById(new_)
        if (a) {
          a.scrollIntoView()
        }
      }
    }

    async init() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        this.course = await courseApi.getById(this.courseId)
        this.setCourseActive(this.course)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    handleFilter(data: any) {
      this.search = data.search
    }

    handleAddModule(data: SectionInterface) {
      this.sectionChoice = data._id
      this.setOpenPopup({
        module: 'createModule',
        isOpen: true
      })
    }

    handleAddSession() {
      this.setOpenPopup({
        module: 'createSection',
        isOpen: true
      })
      if (this.$refs.createSection) {
        // @ts-ignore
        this.$refs.createSection.setData({})
      }
    }

    handleCreateModule() {
      this.init()
    }

    handleCreateSession(data: SectionInterface) {
      this.course.sections?.push(data)
      this.setCourseActive(this.course)
    }

    handleEdit(data: SectionInterface) {
      this.setOpenPopup({
        module: 'createSection',
        isOpen: true
      })
      if (this.$refs.createSection) {
        // @ts-ignore
        this.$refs.createSection.setData(data)
      }
    }

    handleEditSession(data: SectionInterface) {
      const index = findIndex(this.course.sections, value => {
        return value._id === data._id
      })
      if (index !== -1) {
        // @ts-ignore
        this.course.sections[index] = assign(this.course.sections[index], data)
      }
    }

    async handleSectionStatus(data: SectionInterface) {
      try {
        await sectionApi.completeSection(this.courseId, data._id, !data.status || data.status === 'pending' ? 'start' : 'completed')

        const index = findIndex(this.course.sections, value => {
          return value._id === data._id
        })
        if (index !== -1) {
          // @ts-ignore
          this.course.sections[index].status = !data.status || data.status === 'pending' ? 'processing' : 'completed'
        }
      } catch (error) {
        console.log(error)
      }
    }

    async handleResetStatus(data: SectionInterface) {
      try {
        await sectionApi.resetStatusSection(this.courseId, data._id)

        const index = findIndex(this.course.sections, value => {
          return value._id === data._id
        })
        if (index !== -1) {
          // @ts-ignore
          this.course.sections[index].status = 'pending'
        }
      } catch (error) {
        console.log(error)
      }
    }

    async handleRemoveSection(data: SectionInterface) {
      try {
        await sectionApi.delete(this.courseId, data._id)
        this.course.sections = filter(this.course.sections, value => {
          return value._id !== data._id
        })
      } catch (error) {
        console.log(error)
      }
    }

    handleOpenEditNote() {
      this.setOpenPopup({
        isOpen: true,
        module: 'courseNote'
      })
      if (this.$refs.courseNote) {
        // @ts-ignore
        this.$refs.courseNote.setData(this.course)
      }
    }

    handleChangeNote(data: CourseInterface) {
      this.course.summary = data.summary
    }

    handleFindSession(_id: string) {
      document.getElementById(_id)?.scrollIntoView()
      this.sessionSelect = ''
    }

    created() {
      this.init()
    }

    beforeDestroy() {
      this.resetCourseActive()
    }
  }
