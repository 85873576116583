
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
  import getApi from '@/api'
  import QuizApi from '@/modules/courseDetail/api/quizApi'
  import { QuizInterface } from '@/interface/BaseInterface'
  import { filter, includes, kebabCase, deburr } from 'lodash'

  const quizApi: QuizApi = getApi('quiz')

  @Component({ components: {} })
  export default class ListQuiz extends Vue {
    @Prop({ required: false, type: String, default: '' }) courseType?: string

    search = ''

    isLoading = false
    listQuiz: QuizInterface[] = []
    select = ''

    get getListQuizzes() {
      return filter(this.listQuiz, value => {
        return includes(kebabCase(deburr(value.name)), kebabCase(deburr(this.search)))
      })
    }

    async init() {
      try {
        this.isLoading = true
        this.listQuiz = await quizApi.getCloneList(this.courseType)
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    }

    reset() {
      this.search = ''
      this.select = ''
    }

    @Watch('select', { immediate: false }) watchSelect(new_: string) {
      this.$emit('change', new_)
    }

    created() {
      this.init()
    }
  }
