
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { ModuleInterface, ModuleType, FileResultInterface } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import { size, trim } from 'lodash'
  import CourseFileApi from '../../api/courseFileApi'
  import ListQuiz from './quiz/ListQuiz.vue'

  const quizApi: QuizApi = getApi('quiz')
  const courseFileApi: CourseFileApi = getApi('courseFileApi')

  @Component({ components: { ListQuiz } })
  export default class CreateModule extends Vue {
    @Prop({ required: true, type: String }) sectionId!: string
    @Prop({ required: false, type: String, default: '' }) courseType?: string

    data: ModuleInterface = {
      _id: '',
      courseId: this.courseId,
      sectionId: this.sectionId,
      name: '',
      description: '',
      module_type: ModuleType.QUIZ
    }

    result: FileResultInterface = {
      path: '',
      filename: ''
    }

    cloneQuizId = ''

    moduleType = ModuleType

    cloneQuiz = false

    handleSuccess(data: FileResultInterface[]) {
      if (size(data) > 0) {
        this.result = data[0]
        this.data.filePath = this.result.path
        this.data.fileName = this.result.filename
      }
    }

    reset() {
      this.data = {
        _id: '',
        courseId: this.courseId,
        sectionId: this.sectionId,
        name: '',
        description: '',
        module_type: ModuleType.QUIZ
      }
      this.result = {
        path: '',
        filename: ''
      }
      if (this.$refs.listQuiz) {
        //@ts-ignore
        this.$refs.listQuiz.reset()
      }
    }

    handleOpen() {
      this.cloneQuizId = ''
      this.cloneQuiz = false
      this.data.sectionId = this.sectionId
      if (this.$refs.formActive) {
        // @ts-ignore
        this.$refs.formActive.clearValidate()
      }
    }

    handleClose() {
      this.reset()
    }

    handleChangeCloneQuiz(cloneQuizId: string) {
      this.cloneQuizId = cloneQuizId
    }

    async handleSubmit() {
      try {
        // console.log(this.data)

        if (this.data.module_type === this.moduleType.QUIZ && this.cloneQuiz) {
          let data: Record<string, any> = {}
          if (trim(this.cloneQuizId) === '') {
            this.$message.error('Please choose quiz!')
            return
          }
          data = await quizApi.cloneQuiz(this.courseId, this.sectionId, this.cloneQuizId)
          data.module_type = this.moduleType.QUIZ
          this.$emit('submit', data)
          this.$message.success(this.$t('createSuccess') as string)
          return
        }
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            this.data.sectionId = this.sectionId
            this.data.courseId = this.courseId
            let data: Record<string, any> = {}
            if (this.data.module_type === this.moduleType.QUIZ) {
              data = await quizApi.create(this.data)
              data.module_type = this.moduleType.QUIZ
            } else if (this.data.module_type === this.moduleType.ATTACHMENT) {
              data = await courseFileApi.createModuleFile(this.courseId, this.data)
              data.module_type = this.moduleType.ATTACHMENT
            }

            this.$emit('submit', data)
            this.$message.success(this.$t('createSuccess') as string)
            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
